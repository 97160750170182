@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
  .card {
    background: linear-gradient(
      -45deg,
      rgba(242, 242, 242, 0.218),
      rgba(34, 40, 49, 0.1),
      rgba(242, 242, 242, 0.218),
      rgba(242, 242, 242, 0.218)
    );
    background-size: 400% 400%;
    animation: gradient 15s ease infinite;
    width: 100%;
    height: 100%;
    border-radius: 8px;
    border: 1px solid #fff;
  }

  @keyframes gradient {
    0% {
      background-position: 0% 50%;
    }
    50% {
      background-position: 100% 50%;
    }
    100% {
      background-position: 0% 50%;
    }
  }
}

@import url("https://fonts.googleapis.com/css2?family=Poppins&display=swap");

body {
  margin: 0;
  padding: 0;
  background-color: #222831;
  color: #fff;
  font-family: "Montserrat", sans-serif;
}
body p {
  font-size: 22px;
}
.i4ewOd-pzNkMb-haAclf {
  background-color: #0e6fa0 !important;
}
